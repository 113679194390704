import React from "react";
import { Button as ButtonBase, ButtonProps } from "@mui/material";
import { keyframes } from "@emotion/react";

const rotate = keyframes({
  from: { transform: "translateX(-50%) translateY(-50%) rotate(0deg)" },
  to: { transform: "translateX(-50%) translateY(-50%) rotate(360deg)" },
});

export const Button = ({
  children,
  processing,
  sx,
  ...props
}: ButtonProps & { processing?: boolean }) => {
  return (
    <ButtonBase
      {...props}
      sx={{
        position: "relative",
        color: processing ? "primary.main" : "primary.contrastText",
        backgroundColor: "primary.main",
        display: "block",
        width: "100%",
        height: "2.5rem",
        lineHeight: "1.5rem",
        py: "0.5rem",
        border: "none",
        borderRadius: "0.25rem",
        appearance: "none",
        outline: "none",
        textAlign: "center",
        cursor: processing ? "default" : "pointer",
        opacity: "1",
        textTransform: "capitalize",
        transition: "opacity 300ms, color 300ms",
        "&.Mui-disabled": {
          cursor: "default",
          opacity: "0.5",
          color: "common.white",
        },
        ":hover": {
          backgroundColor: "primary.main",
        },
        ":after": {
          content: "''",
          opacity: processing ? "1" : "0",
          position: processing ? "absolute" : "relative",
          top: "50%",
          left: "50%",
          animation: `${rotate} 1s linear infinite`,
          transition: "opacity 600ms 300ms",
          display: "block",
          width: "1.25rem",
          height: "1.25rem",
          borderStyle: "solid",
          borderColor: "primary.contrastText",
          borderWidth: "0.125rem",
          borderTopColor: "transparent",
          borderRadius: "50%",
        },
        ...sx,
      }}
    >
      {!processing && children}
    </ButtonBase>
  );
};
