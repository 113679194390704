import React from "react";
import { Helmet } from "react-helmet";

type SEOProps = {
  title: string;
  description?: string;
  lang?: string;
  meta?: [];
  noindex?: boolean;
};

export const SEO = ({
  title = "",
  description = "",
  lang = "fr",
  meta = [],
  noindex = false,
}: SEOProps) => {
  const pageMeta = [
    ...meta,
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ];
  if (noindex) {
    pageMeta.push({
      name: `robots`,
      content: "noindex",
    });
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={pageMeta}
    />
  );
};
